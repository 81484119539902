import css from './global.module.css';
import Home from './components/home/main.js' 
import Navbar from './components/navigate/navbar/main.js' 
import Footer from './components/navigate/footer/main.js' 
import Build from './components/build/main.js' 
import Simulate from './components/simulate/main.js' 
import Subscriptions from './components/subscription/selection/main.js' 
import Payment from './components/subscription/payment/main.js' 
import Deploy from './components/deploy/main.js' 
import Profile from './components/account/profile/main.js' 
import Logout from './components/account/logout/main.js' 
import Login from './components/account/login/main.js' 
import Signup from './components/account/signup/main.js' 
import About from './components/other/about/main.js' 
import Contact from './components/other/contact/main.js' 
import {HashRouter as Router, Routes, Route} from 'react-router-dom';
import { StateProvider } from './components/state/context.js'



function App() {
    return (
        <div className={css.app_container}>
            <StateProvider>
                <Router> 
                    <Navbar/>
                        <Routes>
                          <Route path="/" element={<Home />} />
                          <Route path="/build" element={<Build />} />
                          <Route path="/simulate" element={<Simulate />} />
                          <Route path="/deploy" element={<Deploy />} />
                          <Route path="/profile" element={<Profile />} />
                          <Route path="/logout" element={<Logout />} />
                          <Route path="/login" element={<Login />} />
                          <Route path="/signup" element={<Signup />} />
                          <Route path="/about" element={<About />} />
                          <Route path="/contact" element={<Contact />} />
                          <Route path="/subscriptions" element={<Subscriptions />} />
                          <Route path="/payment" element={<Payment />} />
                        </Routes>
                    <Footer/>
                </Router> 
            </StateProvider>
        </div>
    );
}

export default App;

import React, { useState } from 'react';
import css from './main.module.css';
import { useNavigate, useLocation } from 'react-router-dom';



export default function Main() {

    const location = useLocation()
    const [plan] = useState(location.state.plan)
    const [cost] = useState(location.state.cost)
    const [email, setEmail] = useState('')
    const [cardDetails, setCardDetails] = useState({ number: '', expiry: '', cvc: '' })

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("Submitting:", email, cardDetails);
    }

    return (
        <div className={css.main}>
            <div className={css.paymentBox}>
                <h2 className={css.title}>Payment for {plan}</h2>
                <h3 className={css.title}>{'£' + cost[0] + ' per ' + cost[1]}</h3>
                <form onSubmit={handleSubmit} className={css.paymentForm}>
                    <input 
                        type="email" 
                        className={css.inputField}
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        placeholder="Email" 
                        required 
                    />
                    <input 
                        type="text" 
                        className={css.inputField}
                        value={cardDetails.number} 
                        onChange={(e) => setCardDetails({ ...cardDetails, number: e.target.value })} 
                        placeholder="Card Number" 
                        required 
                    />
                    <input 
                        type="text" 
                        className={css.inputField}
                        value={cardDetails.expiry} 
                        onChange={(e) => setCardDetails({ ...cardDetails, expiry: e.target.value })} 
                        placeholder="Expiry MM/YY" 
                        required 
                    />
                    <input 
                        type="text" 
                        className={css.inputField}
                        value={cardDetails.cvc} 
                        onChange={(e) => setCardDetails({ ...cardDetails, cvc: e.target.value })} 
                        placeholder="CVC" 
                        required 
                    />
                    <button type="submit" className={css.submitButton}>Pay</button>
                </form>
            </div>
        </div>
    );
}

import React, { useState, useContext } from 'react';
import css from './delete.module.css';
import { StateContext } from '../../../state/context.js'
import { useNavigate } from 'react-router-dom';
import axios from "axios";



export default function DeleteAccount() {

    const [state, dispatch] = useContext(StateContext)
    const navigate = useNavigate()

    const delete_account = (event) => {
        if(window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
            axios.delete('http://localhost:8000/api/users/delete/', {
                headers: {
                    'Authorization': `Token ${state.auth_token}`
                }}).then((response) => {
                    dispatch({ type: 'user_title', payload: 'Login' })
                    dispatch({ type: 'auth_token', payload: '' })
                    localStorage.removeItem('state')
                    navigate('/')
            }).catch(error => {
                console.log(error)
            })
        }
    }


    return (
        <div className={css.main}>
            <div className={css.profileContainer}>
                <button className={css.deleteButton} onClick={()=> delete_account()}>Delete Account</button>
            </div>
        </div>
    );
}

import css from './main.module.css';
import DeleteAccount from './delete/delete.js';



export default function Main() {

    return (
        <div className={css.main}>
            <div className={css.profileContainer}>
                <DeleteAccount />
            </div>
        </div>
    );
}

import css from './main.module.css';


export default function Main() {


	return (
		<div className={css.main}>

		</div>)}



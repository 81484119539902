import React, { useState, useContext } from 'react';
import css from './main.module.css';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../state/context.js'
import axios from "axios";


export default function Signup() {
    const navigate = useNavigate();
    const [state, dispatch] = useContext(StateContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSignup = (event) => {
        event.preventDefault();
        const userData = {
          email: email,
          password: password
        }
        axios.post('http://localhost:8000/api/users/create/', userData).then((response) => {
            const auth_token = response.data['token']
            dispatch({ type: 'auth_token', payload: auth_token })
            dispatch({ type: 'user_title', payload: email.split('@')[0] })
            navigate('/')
        }).catch(error => {
            console.log(error)
        })
    };


    return (
        <div className={css.main}>
            <form onSubmit={handleSignup} className={css.signupForm}>
                <h2>Sign Up</h2>
                <div className={css.inputGroup}>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className={css.inputGroup}>
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className={css.signupButton}>Sign Up</button>
            </form>
        </div>
    );
}

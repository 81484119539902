import React, { useState, useContext } from 'react';
import css from './main.module.css';
import { StateContext } from '../../state/context.js'
import { useNavigate } from 'react-router-dom';
import axios from "axios";


export default function LoginPage() {

    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [state, dispatch] = useContext(StateContext)


    const handleLogin = (event) => {
        event.preventDefault()
        const userData = {
          username: email.split('@')[0],
          password: password
        }
        axios.post('http://localhost:8000/api/users/token/login/', userData).then((response) => {
            const auth_token = response.data['auth_token']
            dispatch({ type: 'auth_token', payload: auth_token })
            dispatch({ type: 'user_title', payload: email.split('@')[0] })
            navigate('/')
        }).catch(error => {
            console.log(error)
        })
    };

    const navigateToSignup = () => {
        navigate('/signup'); 
    };

    return (
        <div className={css.main}>
            <div className={css.loginContainer}>
                <form onSubmit={handleLogin} className={css.loginForm}>
                    <h2>Login</h2>
                    <div className={css.inputGroup}>
                        <label htmlFor="username">Email</label>
                        <input
                            type="text"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className={css.inputGroup}>
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className={css.loginButton}>Login</button>
                </form>
                <div className={css.signupPrompt}>
                    <p>Don't have an account? <button onClick={navigateToSignup} className={css.signupButton}>Sign Up</button></p>
                </div>
            </div>
        </div>
    );
}

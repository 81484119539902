import React from 'react';
import css from './main.module.css';
import { useNavigate } from 'react-router-dom';



export default function Main() {

	const navigate = useNavigate();

	const subscribe = (plan, cost) => {
		navigate('/payment', { state: { plan : plan, cost: cost } })
	}

    return (
        <div className={css.main}>
            <h1 className={css.title}>Choose Your Plan</h1>
            <div className={css.plans}>
                <div className={css.plan}>
                    <h2>Basic</h2>
                    <p>For small teams or individual use</p>
                </div>
                <div className={css.plan}>
                    <h2>Pro</h2>
                    <p>For growing businesses</p>
                </div>
                <div className={css.plan}>
                    <h2>Premium</h2>
                    <p>For large organizations</p>
                </div>
            </div>
            <div className={css.plans}>
                 <div className={css.plan}>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 1</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 2</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 3</div>
                    <div className={css.feature}><span className={css.cross}>✘</span> Feature 4</div>
                    <div className={css.feature}><span className={css.cross}>✘</span> Feature 5</div>
                    <div className={css.feature}><span className={css.cross}>✘</span> Feature 6</div>
                    <div className={css.feature}><span className={css.cross}>✘</span> Feature 7</div>
                    <div className={css.feature}><span className={css.cross}>✘</span> Feature 8</div>
                    <div className={css.feature}><span className={css.cross}>✘</span> Feature 9</div>
                    <div className={css.feature}><span className={css.cross}>✘</span> Feature 10</div>
                </div>
                <div className={css.plan}>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 1</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 2</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 3</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 4</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 5</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 6</div>
                    <div className={css.feature}><span className={css.cross}>✘</span> Feature 7</div>
                    <div className={css.feature}><span className={css.cross}>✘</span> Feature 8</div>
                    <div className={css.feature}><span className={css.cross}>✘</span> Feature 9</div>
                    <div className={css.feature}><span className={css.cross}>✘</span> Feature 10</div>
                </div>
                <div className={css.plan}>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 1</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 2</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 3</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 4</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 5</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 6</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 7</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 8</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 9</div>
                    <div className={css.feature}><span className={css.tick}>✔</span> Feature 10</div>
                </div>
            </div>
            <div className={css.plans}>
                <div className={css.plan_select} onClick={() => { subscribe('Basic', [30, 'month']) }} >
                    <h4>Subscribe to Basic</h4>
                    <h4>£30 / Month</h4>
                </div>
                <div className={css.plan_select} onClick={() => { subscribe('Pro', [65, 'month']) }} >
                    <h4>Subscribe to Pro</h4>
                    <h4>£65 / Month</h4>
                </div>
                <div className={css.plan_select} onClick={() => { subscribe('Premium', [170, 'month']) }} >
                    <h4>Subscribe to Premium</h4>
                    <h4>£170 / Month</h4>
                </div>
            </div>
            <footer className={css.footer}>
            </footer>
        </div>
    );
}

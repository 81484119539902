import { useState } from 'react';


export const initialState = {
	user_title: 'Login',
	auth_token: ''
}

export const reducer = (state, action) => {
	switch (action.type) {
		case 'auth_token':
			return { ...state, 
					 auth_token: action.payload }
		case 'user_title':
			return { ...state, 
					 user_title: action.payload }
		default:
			return state
	}
}
import css from './main.module.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';



export default function Main() {
	
	const navigate = useNavigate();

  return (
	  <div className={css.main}>
	      <div className={css.links}>
	          <div className={css.link_item} onClick={()=> navigate('/about')}>About</div>
	          <div className={css.link_item} onClick={()=> navigate('/contact')}>Contact</div>
	      </div>
	  </div>
  )
  }
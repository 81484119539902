import React from 'react';
import css from './main.module.css';
import { useNavigate } from 'react-router-dom';


export default function Main() {

    const navigate = useNavigate();

    return (
        <div className={css.main}>
            <div className={css.main}>
            
            {/* Introduction Panel */}
            <div className={css.panel} style={{ flexDirection: 'row' }}>
                <div className={css.mockDiv}> {/* Logo or Product Image */}
                    <img src="/path-to-your-logo.png" alt="Image" className={css.image} />
                </div>
                <div className={css.mockDiv}> {/* Value Proposition */}
                    <h1>Your Product Name</h1>
                    <p>One line pitch that sums up the value of your product.</p>
                </div>
            </div>

            {/* Features Panel */}
            <div className={css.panel} style={{ flexDirection: 'row' }}>
                <div className={css.mockDiv}> {/* Feature One */}
                    <h2>Feature One</h2>
                    <p>Short description of Feature One.</p>
                </div>
                <div className={css.mockDiv}> {/* Feature Two */}
                    <h2>Feature Two</h2>
                    <p>Short description of Feature Two.</p>
                </div>
                <div className={css.mockDiv}> {/* Feature Three */}
                    <h2>Feature Three</h2>
                    <p>Short description of Feature Three.</p>
                </div>
            </div>

            {/* Introduction Panel */}
            <div className={css.panel} style={{ flexDirection: 'row' }}>
                <div className={css.mockDiv}> {/* Value Proposition */}
                    <h1>Why this product is special</h1>
                    <p>Explain why this product is unique</p>
                </div>
                <div className={css.mockDiv}> {/* Logo or Product Image */}
                    <img src="/path-to-your-logo.png" alt="Image" className={css.image} />
                </div>
            </div>

		   {/* Testimonials Panel */}
            <div className={css.panel} style={{ flexDirection: 'row' }}>
                <div className={css.mockDiv}> {/* Feature One */}
                    <h2>Testimonial One</h2>
                    <p>Short description of Feature One.</p>
                </div>
                <div className={css.mockDiv}> {/* Feature Two */}
                    <h2>Testimonial Two</h2>
                    <p>Short description of Feature Two.</p>
                </div>
            </div>


            {/* Call-to-Action Panel */}
            <div className={css.panel}>
                <h2>Ready to Get Started?</h2>
                <div className={css.buttonContainer}>
                    <button className={css.actionButton} onClick={() => { navigate('/signup') }}>Sign Up for a Free Trial</button>
                </div>
            </div>

        </div>
       </div>

    );
}


import css from './main.module.css';
import logo from '../../../resources/Logo.png'
import React, { useState, useRef, useEffect, useContext  } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../state/context.js'



export default function Main() {

      const navigate = useNavigate();
      const componentRef = useRef();
      const [isDropdownOpen, setDropdownOpen] = useState(false);
      const [state, dispatch] = useContext(StateContext)


      const toggleDropdown = () => {
        if (state.auth_token) {
            setDropdownOpen(!isDropdownOpen);
        } else {
            navigate('/login/');
        }
      }

      const handleClickOutside = (event) => {
          if (componentRef.current && !componentRef.current.contains(event.target)) { 
            setDropdownOpen(false) 
          }
        }

      useEffect(() => {
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
              document.removeEventListener("mousedown", handleClickOutside);
          }
      }, [])


      return (
          <div className={css.main}>
              <div className={css.logo}>
                <a href="/">
                  <img src={logo} alt="Logo"/>
                </a>
              </div>
              <div className={css.links}>
                  <div className={css.link_item} onClick={()=> navigate('/build')}>Build</div>
                  <div className={css.link_item} onClick={()=> navigate('/simulate')}>Simulate</div>
                  <div className={css.link_item} onClick={()=> navigate('/deploy')}>Deploy</div>
              </div>
              <div className={css.user_title}>
                <button onClick={toggleDropdown}>{state.user_title}</button>
                {isDropdownOpen && state.auth_token && (
                  <div ref={componentRef} className={css.dropdown}>
                      <div className={css.dropdown_item} onClick={() => { navigate('/profile'); toggleDropdown(); }}>Profile</div>
                      <div className={css.dropdown_item} onClick={() => { navigate('/subscriptions'); toggleDropdown(); }}>Subscriptions</div>
                      <div className={css.dropdown_item} onClick={() => { navigate('/logout'); toggleDropdown(); }}>Logout</div>
                  </div>
              )}
              </div>
          </div>
      )
  }
import React, { useContext } from 'react';
import css from './main.module.css';
import { useNavigate } from 'react-router-dom';
import { StateContext } from '../../state/context.js'



export default function LogoutPage({ onLogout }) {

    const navigate = useNavigate();
    const [state, dispatch] = useContext(StateContext)

    const handleLogout = () => {
        dispatch({ type: 'user_title', payload: 'Login' })
        dispatch({ type: 'auth_token', payload: '' })
        localStorage.removeItem('state')
        navigate('/')
    };

    return (
        <div className={css.main}>
            <div className={css.logoutContainer}>
                <h2>Logout</h2>
                <p>Are you sure you want to log out?</p>
                <button className={css.logoutButton} onClick={handleLogout}>Logout</button>
            </div>
        </div>
    );
}
